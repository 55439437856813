import serviceAxios from "../index";

export default {
  //查询商品分类列表
  getGoodsCategoryList: () => {
    return serviceAxios({
      url: "/no-auth/product/productCategory/list",
      method: "get",
    });
  },
  //获取商品列表
  getGoodsList: (params, data) => {
    return serviceAxios({
      url: "/no-auth/product/list",
      method: "post",
      params,
      data,
    });
  },
  //获取商品详情
  getGoodsDetail: (id) => {
    return serviceAxios({
      url: `/no-auth/product/detail/${id}`,
      method: "get",
    });
  },

  //获取商品评论
  getCommentList: (data) => {
    return serviceAxios({
      url: `/no-auth/comment/get`,
      method: "post",
      data,
    });
  },

  //加入购物车
  addCart: (data) => {
    return serviceAxios({
      url: `/h5/cart/add`,
      method: "post",
      data,
    });
  },

  getCartList: () => {
    return serviceAxios({
      url: `/h5/cart/list`,
      method: "get",
    });
  },
};
