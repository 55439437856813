import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import HeadComponent from "@/components/HeadComponent.vue";
import { ref, watch } from "vue";
import { useSliderComponentStore } from "@/store";
import { useRoute } from "vue-router";
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    const show = ref(true);
    const footerShow = ref(false);
    const scStore = useSliderComponentStore();
    const isAndroid = /Android/i.test(navigator.userAgent);

    // 监听slider状态，控制滑动
    watch(() => scStore.show, value => {
      if (value) {
        document.body.style.position = "fixed";
      } else {
        document.body.style.position = "relative";
      }
    });
    watch(() => route.meta.headHidde, value => {
      if (value) {
        show.value = false;
      } else {
        show.value = true;
      }
    }, {
      immediate: true
    });
    watch(() => route.meta.footerHidde, value => {
      if (value) {
        footerShow.value = false;
      } else {
        footerShow.value = true;
      }
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_unref(isAndroid) ? 'container_android' : 'container')
      }, [show.value ? (_openBlock(), _createBlock(HeadComponent, {
        key: 0
      })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_router_view, {
        key: _unref(route).fullPath
      }))], 2);
    };
  }
};