import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import * as store from "./store";
import * as config from "./config";
import piniaPersist from "pinia-plugin-persist";
import dir from "./directive/index";
// 在路由之前请求一些路由id
import axios from "axios";
import { baseURL } from "@/config";

localStorage.setItem("productList", []);

//设置px-rem基准值
import "amfe-flexible";

// import * as Sentry from "@sentry/browser";

const pinia = createPinia();
pinia.use(piniaPersist);
const app = createApp(App);

const isAndroid = /Android/i.test(navigator.userAgent);

const cliWidth = document.documentElement.clientWidth;
document.documentElement.style.fontSize = cliWidth / 1920 + "px";
window.onresize = function () {
  const cliWidth = document.documentElement.clientWidth;
  document.documentElement.style.fontSize = cliWidth / 1920 + "px";
  if (isAndroid) {
    document.documentElement.style.fontSize = (cliWidth * 2) / 1920 + "px";
  }
};

if (isAndroid) {
  document.documentElement.style.fontSize = (cliWidth * 2) / 1920 + "px";
}

import "@fortawesome/fontawesome-free/css/all.min.css";
import "element-plus/dist/index.css";

import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";

app.use(ElementPlus, {
  locale: zhCn,
});

app.provide("$config", config);

async function toGetGoodsIds() {
  let res = await axios.get(baseURL + "/no-auth/product/productIdList");
  localStorage.setItem("productList", res.data.productList);
  localStorage.setItem("productCategoryList", res.data.productCategoryList);
  app.use(store).use(pinia).use(router).mount("#app");
}

toGetGoodsIds();

app.directive("preventReClick", dir.preventReClick);
