import { defineStore } from "pinia";
import { isMobileFlag } from "@/config";

export const useHeadComponentStore = defineStore("HeadComponent", {
  state: () => ({
    height: undefined,
  }),
});
export const isMobileStore = defineStore("Mobile", {
  state: () => ({
    flag: isMobileFlag,
  }),
});
export const useSliderComponentStore = defineStore("SliderComponent", {
  state: () => ({
    show: false,
  }),
});
export const useMobileHeaderStore = defineStore("MobileHeader", {
  state: () => ({
    height: undefined,
  }),
});
export const useUserStore = defineStore("userStore", {
  state: () => ({
    userInfo: {},
  }),
  actions: {
    setUserInfo(info) {
      this.userInfo = info;
    },
    clearUserInfo() {
      localStorage.removeItem("token");
      this.userInfo = {};
    },
  },
  persist: {
    enabled: true,
  },
});
